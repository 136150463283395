<template>
  <div>
    <PageHeader :title="FA_LAN.SETTING.ABOUT_US"/>
    <div class="container p-3 mt-4 bg-white all-card-size col-11 rounded shadow-sm text-center">
      <p class="blue-text p-0">{{ FA_LAN.ABOUT_US.NAME }}</p>
      <div><small>{{ FA_LAN.ABOUT_US.HELP }}</small></div>
      <hr>
      <div class="text-start">
        <p class="blue-text p-0">{{ FA_LAN.ABOUT_US.TEACHERS }}</p>
        <div><small>{{ FA_LAN.ABOUT_US.ELNAZ_JOB }}</small></div>
        <div><small>{{ FA_LAN.ABOUT_US.ELAHE_JOB }}</small></div>
        <hr>
        <p class="blue-text p-0">{{ FA_LAN.ABOUT_US.PHONES }}</p>
        <div><small><span class="text-muted me-2">{{ FA_LAN.ABOUT_US.T0_CALL_ELNAZ }}</span>
          <a href="tel:09139345689">{{ FA_LAN.ABOUT_US.PHONE_ELNAZ }}</a>
          <span class="ms-2">{{ FA_LAN.ABOUT_US.ELNAZ_NAME }}</span></small></div>
        <div><small><span class="text-muted me-2">{{ FA_LAN.ABOUT_US.T0_CALL_ELAHE }}</span>
          <a href="tel:09134729884">{{ FA_LAN.ABOUT_US.PHONE_ELAHE }}</a>
          <span class="ms-2">{{ FA_LAN.ABOUT_US.ELAHE_NAME }}</span></small></div>
        <hr>
        <p class="blue-text p-0">{{ FA_LAN.ABOUT_US.SITE }}</p>
        <div><small> <a href="https://elnazbeauty.ir" target="_blank">{{ FA_LAN.ABOUT_US.SITE_1 }}</a></small></div>
        <div><small> <a href="https://eliznakhon.ir" target="_blank">{{ FA_LAN.ABOUT_US.SITE_2 }}</a></small></div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../components/PageHeader";
import persian from "../fa-IR/fa-ir";

export default {
  name: "AboutUs",
  components: {PageHeader},
  setup() {
    const FA_LAN = persian;

    return {FA_LAN}
  }
}
</script>

<style scoped>

</style>