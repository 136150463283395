const pay ={
    PENDING:"درحال اتصال به درگاه...",
    RESULT:"نتیجه پرداخت",
    RESULT_TITLE_SUCCESS:"پرداخت موفق",
    RESULT_TITLE_WRONG:"پرداخت ناموفق",
    RESULT_CODE:"کد خطا: ",
    DESCRIPTION:"توضیحات: ",
    AMOUNT:"مبلغ: ",
    REF_ID:"کد پیگیری: ",
}

export default pay