<template>
  <div>
    <div class="sticky-top">
      <PageHeader :title="FA_LAN.HEADER.VIDEOS"/>
      <Search @search="searchEducation"/>
    </div>
    <div class="container mt-3 mb-5 all-card-size col-11">
      <p v-if="empty" class="mt-3">{{ FA_LAN.BODY.EMPTY }}</p>
      <div v-else ref='scrollComponent'>
        <div v-for="education in educations" :key="education.id" class="mt-3 p-2 row bg-white shadow-sm rounded">
          <div class="row">
            <div class="col-11">
              <p class="mb-2">
                <span class="blue-text">
                {{ FA_LAN.EDUCATION.LESSON_NUMBER_TITLE }}{{ education.education_number }}
              </span>
                {{ education.title }}</p>
              <div class="secondary_text pb-2"><small>{{ FA_LAN.EDUCATION.TAG }}{{ education.tags }}</small></div>
            </div>
            <div class="col-1 justify-content-end align-content-center">
              <i v-if="education.url" class="bi bi-play-circle text-muted"></i>
            </div>
          </div>
          <div class="d-flex">
            <div class="col-3">
              <div class="orange-text"><small>{{ education.sub_category_title }}</small></div>
            </div>
            <div class="col-9 d-flex justify-content-end align-items-end">
              <div v-if="education.extra_url" class="me-1">
                <a class="btn btn-sm blue-background text-white" :href="education.extra_url" download>
                  <small> {{ FA_LAN.BUTTON.EXTRA_URL }}</small>
                </a>
              </div>
              <div v-if="education.url">
                <router-link class="btn btn-sm blue-background text-white"
                             :to="{name:'showVideo',params:{id:convertHashVideoUrl(education.url),code:code}}">
                  <small> {{ FA_LAN.BUTTON.SEE_EDUCATION }}</small>
                </router-link>
              </div>
              <div v-else>
                <button class="btn btn-sm blue-background text-white" data-bs-toggle="offcanvas"
                        :data-bs-target="'#offcanvasBottom'+education.id" aria-controls="offcanvasBottom">
                  <small> {{ FA_LAN.BUTTON.SEE_EDUCATION }}</small>
                </button>
                <Bottom
                    :id="'offcanvasBottom'+education.id"
                    :title="education.title"
                    :code="education.main_category"
                    :education-id="education.id"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loading :loading="loading"/>
    </div>
  </div>
</template>

<script>
import {useRoute} from "vue-router";
import persian from "../../fa-IR/fa-ir";
import {onMounted, onUnmounted, ref} from "vue";
import {post} from "../../store/mainRoute";
import {sweetAlert} from "../../js/alert/sweetAlert";
import {convertID} from "../../js/protect/encodeVideoId";
import PageHeader from "../PageHeader";
import Loading from "../Loading";
import Search from "../Search";
import Bottom from "../convas/Bottom";

export default {
  name: "MainCategories",
  props: ['code'],
  components: {Bottom, Search, Loading, PageHeader},
  setup() {
    const route = useRoute();
    const educations = ref([]);
    const loading = ref(false);
    const FA_LAN = persian;
    const index = ref(0);
    const search = ref('');
    const scrollComponent = ref(null);
    const empty = ref(false);

    onMounted(() => {
      getEducations();
      window.addEventListener('scroll', loadMore);

    })

    onUnmounted(() => {
      window.removeEventListener('scroll', loadMore);
    })

    function searchEducation(key) {
      search.value = key;
      educations.value = [];
      index.value = 0;
      getEducations();
    }

    async function getEducations() {
      loading.value = true;
      await post(`v1/getEducation?index=${index.value}`, {
        main_category: route.params.code,
        search: search.value
      }).then(function (response) {
        if (response.data.code === 200) {
          educations.value.push(...response.data.data.educations);
        } else {
          sweetAlert('error', response.data.description)
        }
      })
          .catch(function (error) {
            sweetAlert('error', error)
          })
      loading.value = false;
      checKEmptyObject();
    }

    async function loadMore() {
      if (!checkPreviousReceive()) {
        return;
      }

      let element = scrollComponent.value
      if (element.getBoundingClientRect().bottom < window.innerHeight) {

        if (index.value - educations.value.length > 10) { //for stop looping
          return;
        }

        index.value += 10;
        getEducations();
      }
    }

    function checKEmptyObject() {
      empty.value = educations.value.length === 0;
    }

    function convertHashVideoUrl(url) {
      return convertID(url);
    }

    function checkPreviousReceive() {
      return educations.value.length === index.value + 10;
    }

    return {FA_LAN, educations, loading, search, empty, scrollComponent, searchEducation, convertHashVideoUrl}
  }
}
</script>

<style scoped>

</style>