<template>
  <div>
    <div class="sticky-top">
      <div class="navbar blue-background text-white d-flex justify-content-center  pt-3">
        <div class="text-center">
          <h4> {{ FA_LAN.MAIN.TITLE }}</h4>
          <small>{{ FA_LAN.MAIN.HONOR }}</small>
          <hr class="blue-light-text">
          <small>{{ FA_LAN.MAIN.ADVANTAGES }}</small>
        </div>
      </div>
      <div class="container pe-0 ps-0 pb-2 bg-light all-card-size">
        <ul class="nav nav-fill">
          <button class="nav-item blue-text p-3 bg-white border-0" :class="{'tab-active':category}"
                  @click="showCategories">
            {{ FA_LAN.MAIN.MAIN_CATEGORY_TAB }}
          </button>
          <button class="nav-item blue-text p-3 bg-white border-0" :class="{'tab-active':!category}"
                  @click="showPackages">
            {{ FA_LAN.MAIN.PACKAGES_TAB }}
          </button>
        </ul>
      </div>
    </div>
    <div class="container pe-0 ps-0 all-card-size col-11">
      <div class="card pb-5 border-0 bg-light">
        <div class="card-body p-0">
          <div class="p-3">
            <Loading :loading="loading" v-if="loading"/>
            <div v-else>
              <transition name="slide-fade">
                <Categories v-if="category"/>
                <Packages v-else/>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../fa-IR/fa-ir";
import {onMounted, ref} from "vue";
import Categories from "../components/main/Categories";
import Packages from "../components/main/Packages";
import {useStore} from "vuex";
import Loading from "../components/Loading";

export default {
  name: "Home",
  components: {Loading, Packages, Categories},
  setup() {
    const FA_LAN = persian;
    const category = ref(true);
    const store = useStore()
    const loading = ref(true);

    onMounted(() => {
      fetch();
    })

    function showCategories() {
      category.value = true;
    }

    function showPackages() {
      category.value = false
    }

    async function fetch() {
      await store.dispatch('packages/fetch');
      await store.dispatch('categories/fetch');
      loading.value = false
    }

    return {FA_LAN, category, loading, showCategories, showPackages}
  }
}
</script>

<style scoped>

</style>