<template>
  <div>
    <PageHeader :title="FA_LAN.PAY.RESULT" class="sticky-top"/>
    <div class="container">
      <div class="row justify-content-center mt-5">
        <Loading :loading="loading" v-if="loading"/>
        <div v-else class="all-card-size col-11">
          <div v-if="successPay" class="alert alert-success rounded" role="alert">
            <h5>{{ FA_LAN.PAY.RESULT_TITLE_SUCCESS }}</h5>
            <p>{{ FA_LAN.PAY.DESCRIPTION }}{{ verifyResponse.description }}</p>
            <p>{{ FA_LAN.PAY.AMOUNT }}{{ withComma(verifyResponse.amount) }}{{ FA_LAN.PLANS.TOMAN }}</p>
            <p>{{ FA_LAN.PAY.REF_ID }}{{ verifyResponse.refID }}</p>
          </div>
          <div v-else class="alert alert-danger rounded" role="alert">
            <h4>{{ FA_LAN.PAY.RESULT_TITLE_WRONG }}</h4>
            <p>{{ FA_LAN.PAY.DESCRIPTION }}{{ verifyResponse.description }}</p>
            <p>{{ FA_LAN.PAY.RESULT_CODE }}{{ verifyResponse.code }}</p>
          </div>
          <div class="d-flex justify-content-center mb-5 col-12">
            <router-link class="btn btn-sm blue-background text-white" :to="{name:'home'}">{{ FA_LAN.BUTTON.TO_HOME }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useRoute} from "vue-router";
import {onMounted, ref, reactive} from "vue";
import {cancel, verify} from "../js/pay/pay";
import {sweetAlert} from "../js/alert/sweetAlert";
import persian from "../fa-IR/fa-ir";
import {numberWithCommas} from "../js/number";
import {useStore} from "vuex";
import PageHeader from "../components/PageHeader";
import Loading from "../components/Loading";

export default {
  name: "Verify",
  components: {Loading, PageHeader},
  setup() {
    const route = useRoute();
    const loading = ref(true);
    const authority = route.query.Authority;
    const status = route.query.Status;
    const successPay = ref();
    const FA_LAN = persian;
    const verifyResponse = reactive({
      code: '',
      description: '',
      amount: '',
      refID: ''
    });
    const store = useStore();

    onMounted(() => {
      if (status === 'OK') {
        verifyPay();
      } else {
        cancelPay();
      }
    })


    async function verifyPay() {
      await verify(authority)
          .then(function (response) {
            if (response.data.code === 200) {
              successPay.value = response.data.data.code === 100

              verifyResponse.code = response.data.data.code;
              verifyResponse.description = response.data.data.description;
              verifyResponse.amount = response.data.data.amount;
              verifyResponse.refID = response.data.data.refID;

              //update package
              store.dispatch('packages/fetch');

            } else {
              sweetAlert('error', response.data.description)
            }

          })
          .catch(function (error) {

            sweetAlert('error', error)
          })

      loading.value = false;
    }

    async function cancelPay() {
      await cancel(authority)
          .then(function (response) {
            if (response.data.code === 200) {
              successPay.value = false

              verifyResponse.code = response.data.data.code;
              verifyResponse.description = response.data.data.description;
              verifyResponse.amount = response.data.data.amount;
              verifyResponse.refID = response.data.data.refID;

            } else {
              sweetAlert('error', response.data.description)
            }

          })
          .catch(function (error) {

            sweetAlert('error', error)
          })

      loading.value = false;
    }

    function withComma(number) {
      return numberWithCommas(number)
    }

    return {FA_LAN, loading, successPay, verifyResponse, withComma}
  }
}
</script>

<style scoped>

</style>