<template>
  <div></div>
  <footer class="footer mt-auto fixed-bottom text-center ">
    <div class="container py-1 bg-light all-card-size col-11">
<!--      <router-link class="btn btn-link btn-sm text-muted" :to="{name:'setting'}">{{ text }}</router-link>-->
      <router-link class="btn btn-link btn-sm text-muted" :to="{name:'setting'}">{{ FA_LAN.SETTING.PROFILE }}</router-link>
      <span class="ps-2 pe-2">{{ FA_LAN.BUTTON.BTN_SEPERATOR }}</span>
      <a class="btn btn-link btn-sm text-muted text-decoration-none"
         href="https://play.google.com/store/apps/details?id=ir.negahgroup.elnaz.beauty"
         target="_blank">
        {{ FA_LAN.BUTTON.ANDROID }}
      </a>
    </div>
  </footer>

</template>

<script>
import persian from "../fa-IR/fa-ir";
// import {useStore} from "vuex";
// import {computed, onMounted, ref, watch} from "vue";
import {ref} from "vue";

export default {
  name: "Footer",
  setup() {
    const FA_LAN = persian;
    // const store = useStore();
    // const apiKey = computed(() => store.getters["apiKey/get"])
    // const phone = computed(() => store.getters["apiKey/getPhone"])
    const text = ref();

    // onMounted(() => {
    //   text.value = getText();
    // })

    // watch(apiKey, () => {
    //   text.value = getText();
    // })
    //
    // function getText() {
    //   if (apiKey.value) {
    //     return FA_LAN.SETTING.PROFILE + " - " + phone.value
    //   } else {
    //     return FA_LAN.SETTING.PROFILE
    //   }
    // }

    return {FA_LAN, text}
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>