const aboutUs={
    NAME:"بیش از 400 هزار هنرجوی مجازی ",
    HELP:"ما کمکتون میکنیم حرفه ای یاد بگیرین و سریع وارد بازار کار بشین",
    TEACHERS:"مدرسین دوره",
    ELNAZ_JOB:"الناز شجاعی مدرس بین‌المللی آرایش دائم",
    ELAHE_JOB:"الهه شجاعی مدرس کاشت ناخن",
    T0_CALL_ELNAZ:"تلفن تماس بخش آرایش دائم:",
    T0_CALL_ELAHE:"تلفن تماس بخش کاشت ناخن:",
    PHONES:"تلفن های تماس",
    PHONE_ELNAZ:"09139345689",
    PHONE_ELAHE:"09134729884",
    ELNAZ_NAME:"(الناز شجاعی)",
    ELAHE_NAME:"(الهه شجاعی)",
    SITE:"وب سایت",
    SITE_1:"elnazbeauty.ir",
    SITE_2:"eliznakhon.ir",
}
export default aboutUs