<template>
  <div>
    <PageHeader :title="FA_LAN.SETTING.SETTING_TITLE"/>
    <div class="container justify-content-center all-card-size col-11 ">
      <div v-if="apiKey" class="mt-3 pb-3 pt-3 bg-white shadow-sm rounded row align-items-center">
        <div class="col-2 border-end border-secondary "><i
            class="bi bi-person-square d-flex align-items-center justify-content-center "></i></div>
        <div class="col-8"><p class="mb-0 ms-2">
          {{ FA_LAN.SETTING.PROFILE }}
          <span>- {{ phone }}</span></p>
        </div>
        <div class="col-2">
          <button class="btn btn-link btn-sm text-danger text-decoration-none" @click="logOut">{{
              FA_LAN.SETTING.EXIT
            }}
          </button>
        </div>
      </div>
      <router-link v-else class="btn-link text-decoration-none primary-text" :to="{name:'login'}">
        <div class="mt-3 pb-3 pt-3 bg-white shadow-sm rounded row align-items-center">
          <div class="col-2 border-end border-secondary "><i
              class="bi bi-person-square d-flex align-items-center justify-content-center "></i></div>
          <div class="col-10"><p class="mb-0 ms-2">{{ FA_LAN.SETTING.PROFILE }}</p>
          </div>
        </div>
      </router-link>
      <router-link class="btn-link text-decoration-none primary-text" :to="{name:'aboutUs'}">
        <div class="mt-3 pb-3 pt-3 bg-white shadow-sm rounded row align-items-center">
          <div class="col-2 border-end border-secondary "><i
              class="bi bi-info-circle d-flex align-items-center justify-content-center "></i></div>
          <div class="col-10"><p class="mb-0 ms-2 ">{{ FA_LAN.SETTING.ABOUT_US }}</p></div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import PageHeader from "../components/PageHeader";
import persian from "../fa-IR/fa-ir";
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  name: "Setting",
  components: {PageHeader},
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const apiKey = computed(() => store.getters["apiKey/get"])
    const phone = computed(() => store.getters["apiKey/getPhone"])

    function logOut() {
      store.commit('apiKey/remove');
    }

    return {FA_LAN, phone, apiKey, logOut}
  }
}
</script>

<style scoped>

</style>