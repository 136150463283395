import {createRouter, createWebHistory} from "vue-router";
import {showScroll} from "./js/protect/protectInspect";

import Home from "./pages/Home";
import EducationList from "./pages/EducationList";
import Login from "./pages/Login";
import PackageInfo from "./pages/PackageInfo";
import ShowVideo from "./pages/ShowVideo";
import Plans from "./pages/Plans";
import Verify from "./pages/Verify";
import Setting from "./pages/Setting";
import AboutUs from "./pages/AboutUs";
import UpdateProfile from "./pages/UpdateProfile";

const routes = [
    {path: '/', name: "home", component: Home},
    {path: '/educations/:type/:code', name: "educationList", component: EducationList},
    {path: '/login', name: "login", component: Login},
    {path: '/packageInfo/:code', name: "packageInfo", component: PackageInfo},
    {path: '/showVideo/:code/:id', name: "showVideo", component: ShowVideo},
    {path: '/plans/:code', name: "plans", component: Plans},
    {path: '/verify', name: "verify", component: Verify},
    {path: '/setting', name: "setting", component: Setting},
    {path: '/aboutUs', name: "aboutUs", component: AboutUs},
    {path: '/updateProfile', name: "updateProfile", component: UpdateProfile},
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, form, next) => {
    if (to.name === 'showVideo') {
        // preventScroll();
        next();
    } else {
        showScroll();
        next();
    }
})

export default router;