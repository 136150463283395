<template>
  <div>
    <div class="offcanvas offcanvas-bottom" tabindex="-1" :id="id"
         aria-labelledby="offcanvasBottomLabel">
      <div class="offcanvas-header justify-content-center">
        <div class="row all-card-size col-11">
          <h5 class="offcanvas-title text-center purple-text" id="offcanvasBottomLabel">{{
              FA_LAN.OFFCANVAS.TITLE
            }}</h5>
          <hr class="text-center">
          <p v-html="FA_LAN.OFFCANVAS.DESCRIPTION"></p>
          <hr class="text-center">
<!--          <span class="small d-flex">-->
<!--            <button class="btn btn-link btn-sm purple-text text-decoration-none text-start p-0 m-0" @click="goToPay">-->
<!--              {{ FA_LAN.OFFCANVAS.BUY }}{{ title }}-->
<!--            </button>-->
<!--          </span>-->
<!--          <hr class="text-center">-->
          <router-link class="purple-text text-decoration-none" :to="{name:'plans', params:{code:code}}">
            {{ FA_LAN.OFFCANVAS.BUY_ALL }}
          </router-link>
        </div>
        <!--      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>-->
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../../fa-IR/fa-ir";
import {createPayLink} from "../../js/pay/pay";
import {sweetAlert} from "../../js/alert/sweetAlert";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  name: "Bottom",
  props: ['id', 'code', 'title', 'educationId'],
  setup(props) {
    const FA_LAN = persian;
    const router = useRouter();
    const store = useStore();
    const apiKey = computed(() => store.getters["apiKey/get"]);
    const hasName = computed(() => store.getters["apiKey/hasName"]);

    function goToPay() {
      if (!checkLogin()) {
        return;
      }

      createUrl();
    }

    function checkLogin() {
      if (!apiKey.value || !hasName.value) {
        router.push({name: 'login'});
        return false
      }
      return true;
    }

    async function createUrl() {
      sweetAlert('success', FA_LAN.PAY.PENDING)

      const educationID = props.educationId;

      await createPayLink('single', educationID)
          .then(function (response) {
            if (response.data.code === 200) {
             window.location.href = response.data.data.url;

            } else {
              sweetAlert('error', response.data.description)
            }

          })
          .catch(function (error) {
            sweetAlert('error', error)
          })
    }

    return {FA_LAN, goToPay}
  }
}
</script>

<style>

</style>