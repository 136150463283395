import {post} from "../../store/mainRoute";

export async function createPayLink(type, id) {
    const callBack = window.location.protocol + "//" + window.location.host + "/verify"
    return await post('v1/pay/createPayUrl', {
        id: id,
        type: type,
        call_back: callBack
    })
}

export async function verify(authority) {
    return await post('v1/pay/verify', {
        authority: authority,
    })
}

export async function cancel(authority) {
    return await post('v1/pay/cancel', {
        authority: authority,
    })
}