import Swal from "sweetalert2"

export function sweetAlert(type, message) {
    if (type === 'success') {
        return Swal.fire({
            // title: Fa_LAN.sweetAlert.successTitle,
            text: message,
            icon: 'success',
            position: "top",
            showConfirmButton: false,
            toast: true,
            timer:3000,
        })
    } else {
        return Swal.fire({
            // title: Fa_LAN.sweetAlert.errorTitle,
            text: message,
            icon: 'error',
            position: "top",
            showConfirmButton: false,
            toast: true,
            timer:3000,
        })
    }
}