<template>
  <!--  loader-->
  <!--  <div v-if="loading" class="d-flex justify-content-center align-items-center min-vh-100">-->
  <!--    <div class="spinner-border spinner-border-large" role="status"></div>-->
  <!--  </div>-->
  <!--  pages after load-->
  <router-view v-slot="{ Component }">
    <transition name="slide-fade">
      <component :is="Component"/>
    </transition>
  </router-view>
  <!--  footer-->
  <Footer/>
</template>

<script>

import {useStore} from "vuex";
import {computed, onMounted} from "vue";
import Footer from "./components/Footer";
import {preventInspect} from "./js/protect/protectInspect";

export default {
  name: 'App',
  components: {Footer},
  setup() {
    // const loading = ref(true);
    const store = useStore();
    const apiKey = computed(() => store.getters["apiKey/get"])
    onMounted(() => {
      preventInspect();

      fetch();
    })

    async function fetch() {
      if (apiKey.value) {
        await store.dispatch('apiKey/checkLogin');
      }

      if (apiKey.value) {//if still is login
        await store.dispatch('apiKey/getProfile');
      }

    }

  }

}
</script>

<style>
.slide-fade-enter-active {
  transition: all .8s ease;
}

.slide-fade-enter-from {
  transform: translateX(20px);
  opacity: 0;
}

.loader {
  left: 50%;
  margin-left: -4em;
}
</style>
