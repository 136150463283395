import body from "./includes/body";
import main from "./includes/main";
import buttons from "./includes/buttons";
import forms from "./includes/forms";
import headers from "./includes/headers";
import educations from "./includes/educations";
import offconvase from "./includes/offconvase";
import plans from "./includes/plans";
import pay from "./includes/pay";
import setting from "./includes/setting";
import aboutUs from "./includes/aboutUs";

const persian = {
    BODY: body,
    MAIN: main,
    BUTTON: buttons,
    FORM: forms,
    HEADER: headers,
    EDUCATION: educations,
    OFFCANVAS: offconvase,
    PLANS: plans,
    PAY: pay,
    SETTING: setting,
    ABOUT_US: aboutUs,
}

// const persian = Object.assign(main, forms, buttons, tables)

export default persian;