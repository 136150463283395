<template>
  <div>
    <div v-for="item in packages" :key="item.id" class="row mb-3 p-2 pb-0 pe-0 bg-white shadow-sm rounded">
      <div class="row pe-0 col-12">
        <div class="col-4 col-md-3 row justify-content-center align-content-center border-secondary border-end">
          <img :src="item.logo" class="col-12 col-md-10">
        </div>
        <div class="col-8 col-md-9 ps-4 pe-0">
          <div class="col-12">
            <p class="mb-2 pt-2">{{ item.title }}</p>
            <div><small class="secondary_text">{{ item.subtitle }}</small></div>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex pb-2 justify-content-end align-items-end">
        <router-link v-if="item.has_access" class="btn btn-sm purple-background "
                     :to="{name:'educationList', params:{code:item.code,type:'packages'}}">
          <small> {{ FA_LAN.MAIN.SHOW_EDUCATION }}</small>
        </router-link>
        <router-link v-else class="btn btn-sm purple-background"
                     :to="{name:'packageInfo', params:{code:item.code}}">
          <small>{{ FA_LAN.MAIN.BUY_PACKAGES }}</small>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";
import persian from "../../fa-IR/fa-ir";

export default {
  name: "packages",
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const packages = computed(() => store.getters["packages/all"]);

    return {packages, FA_LAN}
  }
}
</script>

<style scoped>

</style>