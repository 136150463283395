<template>
  <div class="d-flex justify-content-center pt-2">
    <div class="col-md-9 ">
      <video playsinline controls controlsList="nodownload">
        <source :src="newUrl" type="video/mp4">
      </video>
    </div>
  </div>
</template>

<script>
import {convertURL} from "../js/protect/encodeVideoId";
import {onMounted, ref} from 'vue';
import {useRoute} from "vue-router";

export default {
  name: "Home",
  setup() {
    const newUrl = ref('');
    const route = useRoute();
    const code = route.params.code;
    const hashedID = route.params.id;

    onMounted(() => {
      convertUrl();
    })

    function convertUrl() {
      newUrl.value = convertURL(code, hashedID);
    }

    return {newUrl}
  }

}
</script>

<style scoped>
video {
  width: 100%;
  height: auto;
}
</style>