const forms = {
    LOGIN_TITLE: "برای بازیابی خریدهای قبلی و یا خرید جدید نیاز است تا به حساب کاربری خود ورود کنید",
    PHONE: "شماره همراه",
    OTP: "کد دریافتی را وارد کنید",
    CODE_SEND: "دریافت کد",
    CODE_CHECK: "تایید",
    WRONG_PHONE: "لطفا شماره همراه معتبری وارد نمایید",
    WRONG_OTP: "لطفا کد 6 رقمی پیامک شده را وارد نمایید",
    SEARCH: "جست و جو کنید",
    RECAPTCHA_ERROR: "ریکپچا تایید نشد",
    NAME: "نام و نام خانوادگی",
    STATE: "استان محل اقامت",
    ACQUAINTED: "نحوه آشنایی با ما",
    EMPTY_NAME: "لطفا نام و نام خانوادگی خود را کامل وارد نمایید",
    PROFILE_UPDATE: "تایید",
}

export default forms