<template>
  <div>
    <div class="navbar blue-background text-white d-flex justify-content-center sticky-top pt-3">
      <div class="text-center">
        <div class="text-end small pb-2"><small>{{ FA_LAN.PLANS.NO_DATE_LIMIT }}</small></div>
        <h4> {{ FA_LAN.PLANS.TITLE }}</h4>
        <hr class="blue-light-text">
        <small>{{ FA_LAN.PLANS.DESCRIPTION }}</small>
      </div>
    </div>
    <div class="container mt-3 mb-5 all-card-size col-11">
      <Loading :loading="loading"/>
      <div v-for="plan in plans" :key="plan.id">
        <div v-if="checkCode(plan.main_category)" class="mt-3 p-2 row bg-white shadow-sm rounded">
          <div class="row p-0 m-0">
            <div class="col-12">
              <p class="mb-2">
                {{ plan.main_category_title }}
                <span class="secondary_text"> {{ plan.sub_category_title }}</span>
              </p>
            </div>
            <div class="col-12 d-flex small">
              <p v-if="plan.base_amount!==plan.amount">
                <span class="secondary_text">{{ FA_LAN.PLANS.MAIN_PRICE }}</span>
                <span class="secondary_text text-decoration-line-through">
                {{ withComma(plan.base_amount) }}{{ FA_LAN.PLANS.TOMAN }}
                </span>
              </p>
              <p class="ms-2">
                <span class="orange-text">
                {{ FA_LAN.PLANS.OFF }}{{ withComma(plan.amount) }}{{ FA_LAN.PLANS.TOMAN }}
                </span>
              </p>
            </div>
          </div>
          <div class="d-flex justify-content-end align-items-end">
            <button class="btn btn-sm purple-background text-white" @click="goToPay(plan.id)">
              <small> {{ FA_LAN.PLANS.BUY }}</small>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../fa-IR/fa-ir";
import {useStore} from "vuex";
import {computed, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {post} from "../store/mainRoute";
import Loading from "../components/Loading";
import {sweetAlert} from "../js/alert/sweetAlert";
import {numberWithCommas} from "../js/number";
import {createPayLink} from "../js/pay/pay";

export default {
  name: "Plans",
  components: {Loading},
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const loading = ref(true);
    const plans = ref([]);
    const routeCode = ref(route.params.code);
    const planID = ref('');
    const apiKey = computed(() => store.getters["apiKey/get"]);
    const hasName = computed(() => store.getters["apiKey/hasName"]);

    onMounted(() => {
      getPlans()
    })

    function checkLogin() {
      if (!apiKey.value || !hasName.value) {
        router.push({name: 'login'});
        return false
      }
      return true;
    }

    async function getPlans() {
      await post('v1/new/getPayPlans')
          .then(function (response) {
            if (response.data.code === 200) {
              plans.value = response.data.data.plans;
            } else {
              sweetAlert('error', response.data.description)
            }
          })
          .catch(function (error) {
            sweetAlert('error', error)
          })
      loading.value = false;
    }

    function checkCode(code) {
      return code === routeCode.value;
    }

    function withComma(number) {
      return numberWithCommas(number)
    }

    function goToPay(id) {
      if (!checkLogin()) {
        return;
      }

      planID.value = id;
      createUrl();
    }

    async function createUrl() {
      sweetAlert('success', FA_LAN.PAY.PENDING)

      await createPayLink('plan', planID.value)
          .then(function (response) {
            if (response.data.code === 200) {
              window.location.href = response.data.data.url;

            } else {
              sweetAlert('error', response.data.description)
            }

          })
          .catch(function (error) {
            sweetAlert('error', error)
          })
    }

    return {FA_LAN, loading, plans, checkCode, withComma, goToPay}
  }
}
</script>

<style scoped>

</style>