<template>
  <div>
    <div v-for="category in categories" :key="category.id"
         class="mb-3 p-2 pe-0 row bg-white shadow-sm rounded">
      <div class="col-4 col-md-3 row justify-content-center align-content-center border-secondary border-end">
        <img :src="category.logo" class="col-12 col-md-10">
      </div>
      <div class="col-8 col-md-5 ps-4">
        <p class="mb-2 pt-2">{{ category.title }}</p>
        <div><small class="secondary_text">{{ FA_LAN.MAIN.LESSON_COUNT }}{{ category.count }}</small></div>
        <div><small class="secondary_text">{{ FA_LAN.MAIN.UPDATE_DATE }}{{ toJalali(category.last) }}</small></div>
      </div>
      <div class="col-12 col-md-4 d-flex justify-content-end align-items-end">
        <router-link v-if="category.count" class="btn btn-sm purple-background "
                     :to="{name:'educationList', params:{code:category.code,type:'categories'}}">
          <small> {{ FA_LAN.MAIN.SHOW_EDUCATION }}</small>
        </router-link>
        <button v-else class="btn btn-sm purple-background">{{ FA_LAN.MAIN.SOON }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import {computed} from "vue";
import persian from "../../fa-IR/fa-ir";
import {gregorian_to_jalali} from "../../js/date/convert";

export default {
  name: "Categories",
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const categories = computed(() => store.getters["categories/all"]);

    function toJalali(date) {
      if (date) {
        const jalali = gregorian_to_jalali(date);
        const explodedDate = jalali.split(' ');
        return explodedDate[0];
      }
    }

    return {categories, FA_LAN, toJalali}
  }
}
</script>

<style scoped>

</style>