<template>
  <div class="blue-background text-white p-2">
    <div class="container d-flex align-content-center align-items-center all-card-size">
      <router-link class="text-white" :to="{name:'home'}"><i class="bi bi-arrow-right pe-2"></i></router-link>
      <span>{{ title }}</span>
    </div>
  </div>
</template>

<script>
import persian from "../fa-IR/fa-ir";

export default {
  name: "PageHeader",
  props: ['title'],
  setup() {
    const FA_LAN = persian

    return {FA_LAN}
  }
}
</script>

<style scoped>

</style>