<template>
  <div>
    <div class="d-flex row justify-content-center mt-3">
      <div class="card border-0 shadow-sm rounded">
        <div class="card-body">
          <form>
            <div class="mb-3">
              <label class="form-label purple-text">{{ FA_LAN.FORM.OTP }}</label>
              <input type="number" class="form-control input" v-model.lazy="otp">
              <div class="form-text text-danger" v-if="wrongOTP">{{ FA_LAN.FORM.WRONG_OTP }}</div>
            </div>
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
              <button type="submit" class="btn btn-sm blue-background text-white  ps-5 pe-5 col-12"
                      @click.prevent="sendOtp" :disabled="loading">
                <div class="spinner-border spinner-border-sm text-white" role="status" v-if="loading"></div>
                {{ FA_LAN.FORM.CODE_CHECK }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../../fa-IR/fa-ir";
import {ref} from "vue";
import store from "../../store";

export default {
  name: "ValidSms",
  setup() {
    const FA_LAN = persian
    const otp = ref('');
    const loading = ref(false);
    const wrongOTP = ref(false);

    async function sendOtp() {
      checkOTP()

      if (wrongOTP.value) {
        return
      }

      loading.value = true
      await store.dispatch('apiKey/checkOtp', otp.value)
      loading.value = false
    }

    function checkOTP() {
      if (otp.value.length !== 6) {
        wrongOTP.value = true;
      } else {
        wrongOTP.value = false;
      }
    }

    return {FA_LAN, otp, loading, wrongOTP, sendOtp}
  }
}
</script>

<style scoped>

</style>