<template>
  <div class="d-flex justify-content-center mt-3 mb-3" v-if="loading">
    <div class="spinner-border" role="status"></div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: ['loading']
}
</script>

<style scoped>

</style>