<template>
  <div>
    <div class="container login-card-size col-11">
      <div class="row justify-content-center mt-5">
        <div class="card border-0 shadow-sm rounded">
          <div class="card-body">
            <Loading :loading="loading" v-if="loading"/>
            <form v-else>
              <div class="mb-3">
                <label class="form-label purple-text">{{ FA_LAN.FORM.NAME }}</label>
                <input type="text" class="form-control input" v-model.lazy="profile.name">
                <div class="form-text text-danger" v-if="emptyName">{{ FA_LAN.FORM.EMPTY_NAME }}</div>
              </div>
              <div class="mb-3">
                <label class="form-label purple-text">{{ FA_LAN.FORM.STATE }}</label>
                <select class="form-select" name="state" v-model.lazy="profile.state_id">
                  <option v-for="state in states" :key="state.id" :value="state.id">{{ state.name }}</option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label purple-text">{{ FA_LAN.FORM.ACQUAINTED }}</label>
                <select class="form-select" name="state" v-model.lazy="profile.acquainted">
                  <option v-for="(acquainted,index) in acquainteds" :key="index" :value="acquainted">{{ acquainted }}
                  </option>
                </select>
              </div>
              <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <button type="submit" class="btn btn-sm blue-background text-white  ps-5 pe-5 col-12"
                        @click.prevent="update" :disabled="formLoader">
                  <div class="spinner-border spinner-border-sm text-white" role="status" v-if="formLoader"></div>
                  {{ FA_LAN.FORM.PROFILE_UPDATE }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../fa-IR/fa-ir";
import {onMounted, reactive, ref} from "vue";
import Loading from "../components/Loading";
import {post} from "../store/mainRoute";
import {sweetAlert} from "../js/alert/sweetAlert";
import {useRouter} from "vue-router";
import {useStore} from "vuex";

export default {
  name: "UpdateProfile",
  components: {Loading},
  setup() {
    const FA_LAN = persian;
    const router = useRouter();
    const store = useStore();
    const loading = ref(true);
    const formLoader = ref(false);
    const states = ref([]);
    const acquainteds = ref([]);
    const emptyName = ref(false);
    const profile = reactive({
      name: '',
      state_id: '',
      acquainted: ''
    })

    onMounted(async () => {
      await getState();
      await getAcquainted()

      loading.value = false
    })

    async function getState() {
      await post('v1/getStates', null)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description)
            } else {
              states.value = response.data.data.states
            }
          })
          .catch(function (error) {
            sweetAlert('error', error)
          })
    }

    async function getAcquainted() {
      await post('v1/getAcquaintedList', null)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description)
            } else {
              acquainteds.value = response.data.data.acquaintedList
            }
          })
          .catch(function (error) {
            sweetAlert('error', error)
          })
    }

    async function update() {
      if (profile.name === '') {
        emptyName.value = true
        return;
      } else {
        emptyName.value = false
      }

      formLoader.value = true;

      await post('v1/updateProfile', profile)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description)
            } else {
              sweetAlert('success', response.data.description)
              store.dispatch("apiKey/getProfile");

              router.push({name: "home"})
            }
          })
          .catch(function (error) {
            sweetAlert('error', error)
          })

      formLoader.value = false;

    }


    return {FA_LAN, loading, profile, states, acquainteds, emptyName, formLoader, update}
  }
}
</script>

<style scoped>

</style>