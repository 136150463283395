const main = {
    TITLE: "آکادمی زیبایی الیز",
    HONOR: "آموزش تخصصی تاتو صورت و خدمات ناخن",
    ADVANTAGES: "آموزش بیش از 400 هزار هنرجو به صورت مجازی ",
    COPY_RIGHT: "تمامی حقوق برای گروه نگاه محفوظ است و انتشار آموزشها مجاز نمی باشد",
    MAIN_CATEGORY_TAB: "آموزش های جامع",
    PACKAGES_TAB: "دوره های آموزشی",
    LESSON_COUNT: "تعداد جلسات: ",
    UPDATE_DATE: "بروزرسانی: ",
    SHOW_EDUCATION: "مشاهده آموزش ها",
    SOON: "به زودی",
    BUY_PACKAGES: "مشاهده جزئیات / خرید",
}

export default main;