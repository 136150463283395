import {post} from "../mainRoute";
import {sweetAlert} from "../../js/alert/sweetAlert";

export const categories = {
    namespaced: true,
    state: {
        categories: []
    },
    mutations: {
        set(state, categories) {
            state.categories = categories
        }
    },
    getters: {
        all(state) {
            return state.categories
        }
    },
    actions: {
        async fetch(context) {
            await post('v1/getMainCategories', null).then(function (response) {
                if (response.data.code === 200) {
                    context.commit('set', response.data.data.mainCategories)
                } else {
                    sweetAlert('error', response.data.description)
                }
            })
                .catch(function (error) {
                    sweetAlert('error', error)
                })
        }
    }
}